export default {
    init() {

    },
    finalize() {

        (function ($) {
            'use strict';

            $(document).ready(function () {

                if (document.cookie.indexOf("appointment_modal_shown=true") < 0) {
                    const d = new Date();
                    d.setTime(d.getTime() + (30*24*60*60*1000));
                    const expires = `max-age=${d.toUTCString()}`;

                    setTimeout(function (){
                        $('#appointmentModal').modal('show');
                        //Modal has been shown, now set a cookie
                        document.cookie = "appointment_modal_shown=true; ${expires}; path=/";
                    }, 3000);
                }

                $('.rss-posts-carousel').owlCarousel({
                    loop: true,
                    nav: true,
                    dots: false,
                    navText: [
                        '<i class="blog-prev-button fas fa-chevron-left"></i>',
                        '<i class="blog-next-button fas fa-chevron-right"></i>'
                    ],
                    items: 3,
                    margin: 20,
                    responsive: {
                        // breakpoint from 0 up
                        0: {
                            items: 1,
                        },
                        // breakpoint from 768 up
                        768: {
                            items: 2,
                        },
                        // breakpoint from 991 up
                        991: {
                            items: 3,
                        }
                    }
                });
            });


            $('.contact-modal-btn').each(function () {
                $(this).on('click', function () {
                    let selectedServiceType = $(this).data('serviceType');
                    $('.service-type select').val(selectedServiceType);
                });
            });

            if ($(window).width() > 992) {
                $(function () {
                    $('.dropdown')
                        .mouseenter(function () {
                            $(this).addClass('show');
                            $(this).children(".dropdown-menu").addClass('show');
                            $(this).children("a").addClass('active').attr("aria-expanded", "true");
                        })
                        .mouseleave(function () {

                            $(this).removeClass('show');
                            $(this).children(".dropdown-menu").removeClass('show');
                            $(this).children("a").removeClass('active').attr("aria-expanded", "false")

                        });
                });
            }

            // $('.dropdown').mouseenter(function(){
            //   if(!$('.dropdown-menu').is(':visible')) { // disable for mobile view
            //
            //     if(!$(this).hasClass('show')) { // Keeps it open when hover it again
            //       console.log(this);
            //       $('.dropdown-menu', this).trigger('click');
            //     }
            //   }
            // });

        })(jQuery);
    },
};