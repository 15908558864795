import jQuery from 'jquery';
import './style.scss';
import 'bootstrap';
// import 'bootstrap/dist/js/bootstrap.bundle.min'

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';

import WebFont from 'webfontloader';

import 'owl.carousel';

import { TweenMax, TimelineLite, Power2, Elastic, CSSPlugin } from 'gsap/TweenMax'

import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { faPhoneAlt, faEnvelope, faCaretRight, faCheck, faChevronRight, faChevronLeft, faBuilding, faPaperPlane, faCommentAlt } from '@fortawesome/pro-solid-svg-icons';
import { faFileDownload } from '@fortawesome/pro-regular-svg-icons'
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';


// Add all icons to the library so you can use it in your page
library.add(faPhoneAlt, faEnvelope, faCaretRight, faCheck, faChevronRight, faChevronLeft, faBuilding, faPaperPlane, faFileDownload, faFacebookF, faLinkedinIn, faCommentAlt);

dom.i2svg();

dom.watch({
    autoReplaceSvgRoot: document,
    observeMutationsRoot: document.body
});

// Initialize webfonts
WebFont.load({
    google: {
        families: ['Droid Sans', 'Source Sans Pro', 'Poppins:400,500,700,900', 'Abril Fatface:400,500,700,900']
    }
});

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
    /** All pages */
    common,
    /** Home page */
    home
    /** About Us page, note the change from about-us to aboutUs. */
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());

